import { useEffect, useState } from "react";
import NextImage from "next/image";
import { Image } from "@components/anti/Image";
import HamburgerMenu from "react-hamburger-menu";
import { useStore } from "src/lib/store";
import Link from "../Link";

const Navbar = ({ theme = "dark", navScroll, handleOpenAccount }) => {
  //States
  const { user_data } = useStore((state) => state);

  return (
    <div className={`nav-top container-lg ${navScroll ? "with-bg" : ""}`}>
      <div className={`nav-wrapper ${theme}`}>
        <div className="col-left">
          <div className="d-lg-none d-block"></div>
        </div>
        <div className="col-middle">
          <div className="menu">
            <div className="logo">
              <Link href="/">
                {/* <Image
                  src="/img/logo/eatlah.png"
                  className="img-fluid"
                  width={navScroll ? 200 : theme === "red" ? 135 : 200}
                  height={navScroll ? 40 : theme === "red" ? 25 : 40}
                /> */}
                <Image
                  src="/img/logo/eatlah-club.png"
                  className="img-fluid"
                  width={105}
                  height={42}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-right">
          {user_data ? (
            <div
              className={`user ${theme} pr-3 pr-lg-0`}
              onClick={handleOpenAccount}
            >
              <i className="air ai-user"></i>
              <span className="d-none d-lg-block">{user_data?.name}</span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );

  // return (
  //   <div
  //     className={`nav-top ${navScroll ? "with-bg" : ""} ${theme} ${
  //       home ? "transparent" : ""
  //     }`}
  //   >
  //     <div className="container">
  //       <div className="wrapper">
  //         <div className="col-left" onClick={() => handleOpen()}>
  //           <div className="burger-menu pointer" onClick={() => handleOpen()}>
  //             {Array(3)
  //               .fill()
  //               .map((_, i) => (
  //                 <div className="line" key={`line-${i}`}></div>
  //               ))}
  //           </div>
  //         </div>
  //         <div className="col-mid">
  //           {home ? (
  //             <NextImage
  //               src={
  //                 home ? "/img/logo/eatlah-white.png" : "/img/logo/eatlah.png"
  //               }
  // width={110}
  // height={24}
  // priority
  //               alt="Eatlah logo"
  //             />
  //           ) : (
  //             <NextImage
  //               src={
  //                 home ? "/img/logo/eatlah-white.png" : "/img/logo/eatlah.png"
  //               }
  // width={200}
  // height={40}
  //               alt="Eatlah Logo"
  //               priority
  //             />
  //           )}
  //         </div>
  //         <div className="col-right" onClick={handleOpenAccount}>
  //           <i className="air ai-user pointer"></i>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default Navbar;
