import React, { useEffect, useState } from "react";

//Components
import Navbar from "@components/anti/Navbar/navbar";
import FullLoading from "src/global/full-loading";
import Toast from "src/global/toast/toast";
import { useStore } from "src/lib/store";
import MobileDrawer from "./anti/Navbar/drawer-menu/drawer-mobile";

const Layout = ({ children, theme = "", noNavbar = false }) => {
  //States
  const { isLoading } = useStore((state) => state);
  const [navScroll, setNavScroll] = React.useState(false);

  const [openAccount, setOpenAccount] = useState(false);

  const handleOpenAccount = () => setOpenAccount(!openAccount);
  const handleClose = () => {
    setOpenAccount(false);
  };
  const changeHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    document.getElementById("main-children").addEventListener(
      "scroll",
      function () {
        var scrollTop = document.getElementById("main-children")?.scrollTop;
        if (scrollTop >= 24) {
          setNavScroll(true);
        } else {
          setNavScroll(false);
        }
      },
      true
    );
  }, []);

  useEffect(() => {
    changeHeight();
    window.addEventListener("resize", changeHeight);
    return () => window.removeEventListener("resize", changeHeight);
  }, []);
  return (
    <div className="desktop-frame" id="desktop-frame">
      {isLoading ? <FullLoading /> : null}
      <Toast />
      {!noNavbar ? (
        <Navbar
          navScroll={navScroll}
          theme={theme}
          handleOpenAccount={handleOpenAccount}
        />
      ) : null}
      <MobileDrawer account open={openAccount} onClose={handleClose} />
      <main id="main-children" className={`${theme}`}>
        {children}
      </main>
    </div>
  );
};

export default Layout;
