import React from "react";
import { RotatingLines } from "react-loader-spinner";

const FullLoading = () => {
  return (
    <div className="full-loading">
      <RotatingLines
        strokeColor="#EB5757"
        strokeWidth="4"
        animationDuration="0.75"
        width="45"
        visible={true}
      />
    </div>
  );
};

export default FullLoading;
