import Link from "../../Link";
// import Logo from "src/assets/img/eatlah.png";
import { Image } from "@components/anti";
import Cookies from "js-cookie";

import {
  link_user_home_page,
  link_user_setting,
  link_user_stamps,
  link_user_voucher,
} from "@components/page_url";
import { useRouter } from "next/router";
import { fn_logout } from "src/lib/functions";
import { useStore } from "src/lib/store";

const navExistList = [
  { id: 1, title: "Menu", url: link_user_home_page },
  { id: 2, title: "Outlets", url: link_user_home_page },
  { id: 3, title: "instagram", url: link_user_home_page },
  { id: 4, title: "contact", url: link_user_home_page },
];

const navNewList = [
  { id: 11, title: "my account", url: link_user_home_page },
  { id: 12, title: "vouchers", url: link_user_voucher },
  { id: 13, title: "Stamp History", url: link_user_stamps },
  { id: 14, title: "settings", url: link_user_setting },
  { id: 15, title: "Logout", url: "/logout" },
];

const MainDrawer = ({
  open = false,
  onClose = () => {},
  className = "",
  existing,
  account,
}) => {
  //Utils
  const Router = useRouter();
  const { setLoading } = useStore((state) => state);

  //Functions
  const handleLogout = async () => {
    setLoading(true);
    let { success } = await fn_logout();
    if (success) {
      Cookies.remove(process.env.NEXT_PUBLIC_ACCESS_TOKEN);
      window.location.reload();
    }
    setLoading(false);
  };

  const handleNavigate = (url) => {
    onClose();
    setTimeout(() => {
      Router.push(url);
    }, 1000);
  };

  return (
    <div
      className={`side-drawer-mobile ${account ? "account-menu" : ""} ${
        existing ? "existing fadeInUp" : ""
      } d-md-none ${className} ${open ? "open" : ""}`}
    >
      <div className="main-drawer-wrapper">
        <div className="times-icon" onClick={onClose}>
          <i
            className="air ai-times"
            onClick={onClose}
            role="button"
            tabIndex={0}
          />
        </div>
        {existing ? (
          <div className="account-icon" onClick={onClose}>
            <i
              className="air ai-user"
              onClick={onClose}
              role="button"
              tabIndex={0}
            />
          </div>
        ) : null}
        <div className="container">
          <div className="drawer-header">
            <div
              className="image-wrapper"
              onClick={() => handleNavigate(link_user_home_page)}
            >
              <Image
                src={"/img/logo/eatlah-club.png"}
                width={120}
                height={40}
              />
            </div>
          </div>

          <div className="wrapper-menu mt-5">
            <ul className="menu">
              {existing
                ? navExistList?.map((item) => (
                    <li
                      className="menu-item"
                      key={`${item.id}-side-nav`}
                      onClick={() => {
                        handleNavigate(item.url);
                      }}
                    >
                      {item.title}
                    </li>
                  ))
                : null}
              {!existing
                ? navNewList?.map((item) => (
                    <li
                      className="menu-item"
                      key={`${item.id}-side-nav`}
                      onClick={() => {
                        if (item.url == "/logout") {
                          handleLogout();
                        } else {
                          handleNavigate(item.url);
                        }
                      }}
                    >
                      {item.title}
                    </li>
                  ))
                : null}
              {/* <li className="menu-item" onClick={handleLogout}>
                logout
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <Link
        className="side-drawer-footer container"
        href="https://antikode.com"
        target="_blank"
      >
        <div className="site-by">
          Powered By
          <Image src="/img/logo/antikode.png" width={90} height={16} />
        </div>
        <i className="air ai-chevron-right"></i>
      </Link>
    </div>
  );
};

export default MainDrawer;
